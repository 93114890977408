<template>
    <div> <!-- do not remove empty root element. solves hover shadow delay in grid -->
        <div 
            class="
                panel
                hover:shadow-2xl 
                h-full
                transition 
                duration-200 
                ease-in-out
                cursor-pointer
                flex
                
                flex-row
                text-sm
                
                md:flex-col
                md:text-base
                overflow-hidden
            "
            @click="$emit('edit', product);">
            
            <div 
                class="
                    flex items-center 
                    -m-4
                    mr-4
                    
                    md:-m-8 
                    md:mb-2 
                ">
                <div 
                    class="
                        bg-gray-100 flex items-center justify-center
                        
                        w-32
                        h-32
                        
                        md:w-full 
                        md:h-48
                        overflow-hidden
                    ">
                    
                    <img 
                        v-if="product.coverUrl" 
                        :src="product.coverUrl" 
                        alt="Product Image"
                        class="w-full h-full object-cover"
                    />
                </div>
            </div>
            <div class="md:p-4 md:text-center">
                <h2 class="text-black leading-none my-2 md:my-4">
                    <i 
                        v-if="product.owner && product.owner.username && user && user.username == product.owner.username"
                        class="fas fa-star text-orange-500 mr-1 text-lg"
                        title="You are the creater of this product"
                    />
                    
                    {{product.name}}
                </h2>
                <div class="my-1">
                    {{ product.price ? currencyFormat( product.price ): ''}}
                </div>
                
                <div class="text-red-500" v-if="product.deletedAt">
                    {{$t('general.deleted')}} {{ product.deletedAt | moment('from') }}
                </div>
                <div class="text-gray-500" v-else>
                    <span v-if="product.createdAt != product.updatedAt">
                        {{$t('general.updated')}} {{ product.updatedAt | moment('from') }}
                    </span>
                    <span v-else>
                        {{$t('general.created')}} {{ product.createdAt | moment('from') }}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        product: {
            type: Object,
            required: true,
        }
    },
    
    computed: {
        user(){
            return this.$store.getters.getUser;
        },
    },
}
</script>

<style lang="scss" >

</style>
